angular.module("app")
    .directive("cEditMapTools", function ($rootScope, _v, _mapFieldService) {
        return {
            restrict: "E",
            templateUrl: "t-c-edit-map-tools",
            replace: true,
            link: function ($scope) {

                $scope.editMapCancel = function() {
                    $rootScope.$broadcast('cEditMapTools:cancel');
                };

                $scope.editMapSave = function() {
                    $rootScope.$broadcast('cEditMapTools:save');
                };

                $scope.tool = 'hand';

                $scope.setTool = function(tool) {
                    $scope.tool = tool;
                    _mapFieldService.setTool($scope.tool);
                };

                $scope.$on('mapService:fieldCompleted', function(event, data) {
                    $rootScope.$safeApply(function() {
                        $scope.tool = 'hand';
                        _mapFieldService.setTool($scope.tool);
                    });
                });
            }
        }
    });